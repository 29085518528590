@font-face {
  font-family: 'Rubik';
  src: url('./Rubik/static/Rubik-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Rubik';
  src: url('./Rubik/static/Rubik-Bold.ttf') format('truetype');
  font-weight: 700;
}
